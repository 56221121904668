export class MainService {
  //   constructor(public override injector: Injector) {
  //     super(injector);
  //   }

  //   initNode = (unsubscribe$: Subject<void>) => {
  //     const serverUrl = environment.apiUrls.init.get;
  //     return this.httpClientWrapper
  //       .get(serverUrl)
  //       .pipe(
  //         map(this.extractHttpResponse),
  //         catchError(this.handleErrorObservable)
  //       );
  //   }

  isValidToken = (token: string): boolean => {
    const exp = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) < exp;
  };
}
