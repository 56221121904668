import React, { useState } from "react";
import CiscoLogoSVG from "../../assets/images/cisco-logo.svg";
import DNASpacesLogoSVG from "../../assets/images/dnaspaces-logo.svg";
import WebexLogoSVG from "../../assets/images/webex-logo.svg";
import WebexSVG from "../../assets/images/webex.svg";
import "./Footer.css";
import { DataService } from "../../utils/DataService";
import { Constants } from "../../models/constants";

function Footer() {
  const DataServiceObj = new DataService();
  const [showWebex, setShowWebex] = useState(false);
  DataServiceObj.getDeviceType().then((deviceType) => {
    setShowWebex(deviceType === Constants.DEVICE.WEBEX);
  });

  return (
    <div
      className="footer"
      style={{ display: "flex", justifyContent: "center", padding: "4em" }}
    >
      <div
        className="powered-by"
        style={{ color: "white", paddingRight: "1em" }}
      >
        Powered By
      </div>
      <div
        className="cisco-dnaspaces-logo"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="cisco-logo">
          <img src={CiscoLogoSVG} />
        </div>
        <div className="dnaspaces-logo" style={{ marginLeft: "1em" }}>
          <img src={DNASpacesLogoSVG} />
        </div>
      </div>
      {showWebex && (
        <div
          className="webex-logo-wrapper"
          style={{
            display: "inline-block",
            marginLeft: "1em",
          }}
        >
          <div className="webex-logo" style={{ float: "left" }}>
            <img style={{ height: "2em" }} src={WebexLogoSVG} />
          </div>
          <div
            className="webex-text-logo"
            style={{ float: "left", marginLeft: "0.2em" }}
          >
            <img src={WebexSVG} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
