import React, { useEffect, useState } from "react";
import "./App.css";
import Loader from "./components/loader/Loader";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import ExtractParam from "./components/extract-param/ExtractParam";
import { environment } from "./environments/environment";
import { DataService } from "./utils/DataService";
import DisplayCode from "./components/display-code/DisplayCode";
import { Constants } from "./models/constants";
import Configure from "./components/configure/Configure";
import Frame from "./components/frame/Frame";

const WS_ENDPOINT = `wss://${document.location.host}/init`;
// const serverUrl = "https://${document.location.host}/client/v1/signage/init";

const toastStyle: any = {
  color: "white",
  backgroundColor: "#006200",
  marginBottom: "10px",
  fontSize: "14px",
  textAlign: "center",
  padding: "1em",
  zIndex: 100002,
  borderBottomLeftRadius: "10px",
  borderBottomRightRadius: "10px",
  // top: "-95px",
  position: "fixed",
  width: "fit-content",
  maxWidth: "50%",
  webkitAnimation: "toastMessage 0.5s forwards",
  webkitAnimationDelay: "0.1s",
  animation: "toastMessage 0.5s forwards",
  animationDelay: "0.1s",
};

let activationCodeObtained = false;
let socket;
let heartbeatInterval;
const detectWebGLContext = async () => {
  // import function from data service later
  try {
    // Create canvas element. The canvas is not added to the
    // document itself, so it is never displayed in the
    // browser window.
    var canvas = document.createElement("canvas");
    // Get WebGLRenderingContext from canvas element.
    var gl =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    // Report the result.
    if (gl && gl instanceof WebGLRenderingContext) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

const getDeviceParameters = async () => {
  // import function from data service later
  return {
    "user-agent": navigator.userAgent,
    width:
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth,
    height:
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight,
    "web-gl": await detectWebGLContext(),
  };
};

function App() {
  const navigate = useNavigate();

  console.log("document.location.host -> ", document.location.host);

  useEffect(() => {
    console.log("App component rendered");

    const DataServiceObj = new DataService();

    socket = new WebSocket(WS_ENDPOINT);

    const eventHandlers = async (event) => {
      let eventJSON: any = {};
      eventJSON = JSON.parse(event.data);
      console.log("Websocket event received -> ", eventJSON);

      switch (eventJSON.event) {
        case "status":
          console.log("Status event from server");
          if (eventJSON.payload.connected) {
            const tenantId = await DataServiceObj.getTenantIdMasked();
            console.log(
              "activated?",
              DataServiceObj.isActivated(),
              "TenantIdMasked:",
              tenantId
            );
            if (!DataServiceObj.isActivated()) {
              if (tenantId !== "") {
                const payload: { [header: string]: any } = {
                  tenantMask: tenantId,
                  deviceParam: await getDeviceParameters(),
                  timestamp: new Date().getTime(),
                };
                const deviceId = await DataServiceObj.getDeviceId();
                const orgId = await DataServiceObj.getOrgId();
                // const deviceId: any =
                //   "Y2lzY29zcGFyazovL3VybjpURUFNOnVzLXdlc3QtMl9yL0RFVklDRS82ZTRmN2Q1Ni1lZTEzLTQ2N2EtYjczOS1lZWY5NDgwZWNhZGE=";
                // const orgId: any =
                //   "Y2lzY29zcGFyazovL3VybjpURUFNOnVzLXdlc3QtMl9yL09SR0FOSVpBVElPTi82YmRkYTVmMi04OWVkLTQ3ZDEtYTcyZC03NTg2ZmQ4ZGUxMGU=";
                const isNav = await DataServiceObj.getIsNavigator();
                if (
                  deviceId != undefined &&
                  deviceId != "" &&
                  orgId != undefined &&
                  orgId != "" &&
                  deviceId !== null &&
                  orgId !== null &&
                  deviceId !== "null" &&
                  orgId !== "null"
                ) {
                  console.log("Here -> ", deviceId, orgId);
                  payload["deviceId"] = deviceId;
                  payload["orgId"] = orgId;
                  payload["isNav"] = isNav === "true";
                }
                const token = await DataServiceObj.getDeviceToken();
                const uid = await DataServiceObj.getUid();
                if (token && uid) {
                  payload["uid"] = uid; // needed to uniquely identify device
                }

                const payloadReq = JSON.stringify({
                  event: "init",
                  payload,
                  timestamp: new Date().getTime(),
                });
                socket.send(payloadReq);
                const activationCode = await DataServiceObj.getActivationCode();
                if (activationCode !== null) {
                  fetchUrl();
                  // navigate to code page
                  navigate(Constants.PAGES.DISPLAY_CODE);
                }
              } else {
                console.log("Tenant id is Not present in JS variable");
                // navigate(Constants.PAGES.LOADER);
                // this.dataService.setToastMessage(
                //   "Please provide tenant id in url",
                //   5000,
                //   null,
                //   null,
                //   "error"
                // );
              }
            } else {
              console.log("App is activated!!");
              handleWhenActivated();
              // sendInitRequest();
              const tenantId = await DataServiceObj.getTenantIdMasked();
              const payload: { [header: string]: any } = {
                tenantMask: tenantId,
                deviceParam: await getDeviceParameters(),
                timestamp: new Date().getTime(),
              };
              const deviceId = await DataServiceObj.getDeviceId();
              const orgId = await DataServiceObj.getOrgId();
              // const deviceId: any =
              //   "Y2lzY29zcGFyazovL3VybjpURUFNOnVzLXdlc3QtMl9yL0RFVklDRS82ZTRmN2Q1Ni1lZTEzLTQ2N2EtYjczOS1lZWY5NDgwZWNhZGE=";
              // const orgId: any =
              //   "Y2lzY29zcGFyazovL3VybjpURUFNOnVzLXdlc3QtMl9yL09SR0FOSVpBVElPTi82YmRkYTVmMi04OWVkLTQ3ZDEtYTcyZC03NTg2ZmQ4ZGUxMGU=";
              const isNav = await DataServiceObj.getIsNavigator();
              if (
                deviceId != undefined &&
                deviceId != "" &&
                orgId != undefined &&
                orgId != "" &&
                deviceId !== null &&
                orgId !== null &&
                deviceId !== "null" &&
                orgId !== "null"
              ) {
                console.log("Here -> ", deviceId, orgId);
                payload["deviceId"] = deviceId;
                payload["orgId"] = orgId;
                payload["isNav"] = isNav === "true";
              }
              const token = await DataServiceObj.getDeviceToken();
              const uid = await DataServiceObj.getUid();
              if (token && uid) {
                payload["uid"] = uid; // needed to uniquely identify device
              }

              const payloadReq = JSON.stringify({
                event: "init",
                payload,
                timestamp: new Date().getTime(),
              });
              socket.send(payloadReq);
            }
            sendHeartBeat(socket);
          }
          break;
        case "init":
          console.log("Init event from server");
          if (eventJSON.payload.uid) {
            DataServiceObj.setUid(eventJSON.payload.uid);
          }
          if (eventJSON.payload.deviceType) {
            DataServiceObj.setDeviceType(eventJSON.payload.deviceType);
          }
          if (eventJSON.payload.deviceName) {
            DataServiceObj.setDeviceName(eventJSON.payload.deviceName);
          }
          const getDeviceType = await DataServiceObj.getDeviceType();
          if (getDeviceType === Constants.DEVICE.WEBEX) {
            navigate(Constants.PAGES.CONFIGURE);
          }
          if (eventJSON.payload.code) {
            DataServiceObj.setActivationCode(eventJSON.payload.code);
            navigate(Constants.PAGES.DISPLAY_CODE);
          } else {
            const getUrl = await DataServiceObj.getFrameUrl();
            if (getUrl === null) {
              fetchUrl();
            } else {
              // navigate to showFrame page
              navigate(Constants.PAGES.SHOW_FRAME);
            }
          }
          break;
        case "setToken":
          console.log("SetToken event from server");
          if (eventJSON.payload["token"]) {
            DataServiceObj.setDeviceToken(eventJSON.payload["token"]);
            //DataServiceObj.setInternalEvent({ 'setToken': new Date().getTime() })
          }
          if (eventJSON.payload["deviceName"]) {
            DataServiceObj.setDeviceName(eventJSON.payload["deviceName"]);
          }
          const getUrl = await DataServiceObj.getFrameUrl();
          if (getUrl === null) {
            fetchUrl();
            navigate(Constants.PAGES.CONFIGURE);
          } else {
            //DataServiceObj.setInternalEvent({ 'showFrame': new Date().getTime() })
            // navigate to showFrame page
            navigate(Constants.PAGES.SHOW_FRAME);
          }
          break;
        case "showFrame":
          console.log("ShowFrame event from server");
          if (eventJSON.payload["url"]) {
            DataServiceObj.setFrameUrl(eventJSON.payload["url"]);
            // this.dataService.setInternalEvent({ 'showFrame': new Date().getTime() })
            setTimeout(() => navigate(Constants.PAGES.SHOW_FRAME), 1000);
            navigate(Constants.PAGES.LOADER);
          }
          break;
        case "refresh":
          console.log("Refresh event from server");
          if (eventJSON.payload["url"]) {
            DataServiceObj.setFrameUrl(eventJSON.payload["url"]);
          }
          const geturl = await DataServiceObj.getFrameUrl();
          if (geturl === null) {
            fetchUrl();
          } else {
            //this.dataService.setInternalEvent({ 'showFrame': new Date().getTime() })
            // navigate to showFrame page
            setTimeout(() => navigate(Constants.PAGES.SHOW_FRAME), 1000);
            navigate(Constants.PAGES.LOADER);
          }
          break;
        case "activate":
          console.log("Activate event from server");
          if (eventJSON.payload["status"]) {
            DataServiceObj.setActivated(true);
            console.log("App is activated!!");
            handleWhenActivated();
          } else {
            DataServiceObj.setActivated(false);
          }
          break;
        case "delete":
          console.log("Delete event from server");

          break;
        default:
          console.log("Not implemented event::");
          break;
      }
    };

    socket.onmessage = async (event) => eventHandlers(event);

    // console.log("Socket -> ", socket);

    // Adding token, test without this once
    // DataServiceObj.setDeviceToken(
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJkZXZpY2VUeXBlIjoibm9ud2ViZXgiLCJpYnkiOiJybXNfdG9rZW4iLCJpZFR5cGUiOiJpZCIsInRlbmFudElkIjoiMjE5MjUiLCJpZCI6IjBiM2QyMjZiLTk4YmUtNGFhYy1iMGM1LTRlYmUzZWM0MGQwYyIsInR5cGUiOiJ1c2VyX2FjY2Vzc190b2tlbiIsImV4cCI6MTcwNjI0NDE1MCwiaWF0IjoxNzA2MTU3NTcwfQ.b5bRR1XBLNoS4_dWDTkWzxvOIu8zxF95fyW7a5_lybsRMAjyeERBcSKTo1UxU6ANJnAt_ew8z6Cd6nd_b7TyccIRLnECqtQn07lqfDbn6TJUIQ5eZvM__pS_BUuJJIAGS6uMjFo3uvdzeuKUN4Q9mFR1ywBDaaitUud3ejQFirDSoJtBksUDKnIjxSTrkyEuXgdYoT8BUEeDb7WigQfHPfcEAwXQSia317sdN__mE14y7EkDLiDUvTp2AsWThYpIrL00qSffgRtoymU4RDIgujaHWjdoxSV1p2dwxHOMlkecrk5DYAGwvrRrfYi34KnkUsVE_SttOz32dTE54ikV2g"
    // );

    socket.onclose = () => {
      console.log("Socket closed");
      // socket = new WebSocket(WS_ENDPOINT);
      // socket.addEventListener("open", (event) => {
      //   console.log("Connection with server established");
      // });
      // socket.onmessage = async (event) => eventHandlers(event);
      // sendHeartBeat(socket);
    };

    setTimeout(async () => {
      const frameUrl =
        (await DataServiceObj.getFrameUrl()) !== null &&
        (await DataServiceObj.getFrameUrl()) !== "null";
      const token =
        (await DataServiceObj.getDeviceToken()) !== null &&
        (await DataServiceObj.getDeviceToken()) !== "null";
      console.log("setActivated -> ", token && frameUrl);
      DataServiceObj.setActivated(token && frameUrl);
    }, 0);

    const sendHeartBeat = (socket) => {
      setTimeout(async () => {
        const token = await DataServiceObj.getDeviceToken();
        let payloadReq = {};
        if (token) {
          payloadReq["token"] = token;
        }
        sendHeartBeat(socket);
        const payLoadReq = JSON.stringify({
          event: Constants.EVENTS.WRITE.HEARTBEAT,
          payload: {
            token: token,
          },
        });

        socket.send(payLoadReq);
      }, Constants.HEART_BEAT_INTERVAL);
    };

    // heartbeatInterval = setInterval(async () => {
    //   const token = await DataServiceObj.getDeviceToken();
    //   let payloadReq = {};
    //   if (token) {
    //     payloadReq["token"] = token;
    //   }
    //   //this.sendHeartBeat();

    //   const payLoadReq = JSON.stringify({
    //     event: Constants.EVENTS.WRITE.HEARTBEAT,
    //     payload: {
    //       token: token,
    //     },
    //   });
    //   socket.send(payLoadReq);
    // }, Constants.HEART_BEAT_INTERVAL);

    const fetchUrl = () => {
      DataServiceObj.getDeviceToken().then((token) => {
        const payloadReq = JSON.stringify({
          event: Constants.EVENTS.WRITE.FETCH_URL,
          payload: {
            token: token,
          },
          timestamp: new Date().getTime(),
        });
        socket.send(payloadReq);
      });
    };

    const handleWhenActivated = async () => {
      const getUrl = DataServiceObj.getFrameUrl();
      if (getUrl === null) {
        const activationCode = DataServiceObj.getActivationCode();
        fetchUrl();
        if (activationCode !== null) {
          // navigate to code page
          navigate(Constants.PAGES.DISPLAY_CODE);
        }
      } else {
        setTimeout(() => navigate(Constants.PAGES.SHOW_FRAME), 1000);
        navigate(Constants.PAGES.LOADER);
      }
    };

    socket.addEventListener("open", (event) => {
      console.log("Connection with server established");
    });

    // fetch(serverUrl)
    // .then(res => res.json())
    // .then(data => {
    //   console.log("data res ", data);
    //   // Web Socket connection is to be made here, but CORS Error
    // })
    // .catch(err => {
    //   console.log("Error fetch ", err)
    // })

    DataServiceObj.getDeviceType().then((deviceType) => {
      console.log("Device type ", deviceType);
    });
    DataServiceObj.getDeviceToken().then((token) => {
      console.log("Device token ", token);
    });
  }, []);

  const ToastMessage = ({ message, time }) => {
    const [showToast, setShowToast] = useState(true);
    setTimeout(() => {
      setShowToast(false);
    }, time);
    return showToast ? (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div id="toastMessage" style={toastStyle}>
          {message}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div className="App" style={{ backgroundColor: "black" }}>
      <Routes>
        <Route path="/loader" element={<Loader />}></Route>
        <Route
          path="/t/:tenantMask"
          element={
            <>
              <ToastMessage
                message="Please wait while we send request to link the device."
                time={4000}
              />
              <ExtractParam />
              <Loader />
            </>
          }
        ></Route>
        <Route path="/displayCode" element={<DisplayCode />}></Route>
        <Route path="/configure" element={<Configure />}></Route>
        <Route path="/showFrame" element={<Frame socket={socket} />}></Route>
      </Routes>
    </div>
  );
}

export default App;
