import WorkSpacesLogoSVG from "../../assets/images/workspaces-logo.svg";
import "./Loader.css";
import Footer from "../footer/Footer";
import { useEffect } from "react";

function Loader() {
  useEffect(() => {
    var style = document.createElement("style");
    style.innerHTML = `
    .loader-wrapper {
      width: 100vw;
      height: 100vh;
      text-align: center;
      background: rgba(0, 0, 0);
      .loader {
        display: inline-block;
        position: relative;
        width: 10vw;
        height: 15vw;
        div {
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: 10vw;
          height: 10vw;
          margin: 0.5vw;
          border: 0.5vw solid #00bceb;
          border-radius: 50%;
          animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: #00bceb transparent transparent transparent;
        }
        div:nth-child(1) {
          animation-delay: -0.45s;
        }
        div:nth-child(2) {
          animation-delay: -0.3s;
        }
        div:nth-child(3) {
          animation-delay: -0.15s;
        }
      }
    }
    
    @keyframes loader-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    /* // div#splash {
        //   width: 100vw;
        //   height: 100vh;
        //   position: fixed;
        //   top: 0;
        //   padding-top: 40vh;
        
        //   .logo {
        //     margin: auto;
        //     width: 10vw;
        //     display: flex;
        //     text-align: center;
        //     position: relative;
        //     padding: 5vh;
        //     &::after,
        //     &::before {
        //       content: "";
        //       position: absolute;
        //       top: 0;
        //       bottom: 0;
        //       right: 0;
        //       left: 0;
        //       border-radius: 50%;
        //     }
        //     &::after {
        //       animation: pulse 1500ms infinite;
        //       background-color: #00bceb;
        //     }
        //   }
        // }
        
        // @keyframes pulse {
        //   0% {
        //     transform: scale(1);
        //     opacity: 0.25;
        //   }
        //   100% {
        //     transform: scale(2);
        //     opacity: 0;
        //   }
        // } */
    
    `;
    document.getElementsByTagName("head")[0].appendChild(style);

    document.getElementsByClassName("loader-wrapper")[0].className =
      "loader-wrapper";
  }, []);
  return (
    <div
      className="loader-wrapper"
      style={{ background: "rgba(0, 0, 0)", width: "100vw", height: "100vh" }}
    >
      <div className="loader-text">
        <div className="loader" style={{ paddingTop: "4em" }}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div
          style={{
            transform: `scale(${window.innerWidth / 1200})`,
            display: "flex",
            justifyContent: "center",
            padding: "1em",
          }}
          className="workspaces-logo"
        >
          <img src={WorkSpacesLogoSVG} />
        </div>
        <div
          className="logo-text"
          style={{
            textAlign: "center",
            color: "white",
            fontWeight: "600",
            paddingTop: "2em",
          }}
        >
          Enabling a safe, intelligent, and collaborative experience
        </div>
      </div>
      <div style={{ color: "white" }}></div>
      <Footer />
    </div>
  );
}

export default Loader;
