import React, { useEffect, useState } from "react";
import "./DisplayCode.css";
import SampleMeetingsSVG from "../../assets/images/sample-meetings.svg";
import { Constants } from "../../models/constants";
import WorkSpacesLogoSVG from "../../assets/images/workspaces-logo.svg";
import { DataService } from "../../utils/DataService";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";

const liStyle: object = {
  paddingTop: "1vw",
  paddingLeft: "1.4em",
  left: "0vw",
  marginTop: "0.3vw",
  fontWeight: "200",
  borderRadius: "2vw",
  transition: "all 0.3s ease-out",
};

function DisplayCode() {
  const navigate = useNavigate();
  const [codeToShow, setCodeToShow] = useState(". . . .");
  const DataServiceObj = new DataService();

  DataServiceObj.getFrameUrl().then((frameUrl) => {
    if (frameUrl) {
      navigate(Constants.PAGES.SHOW_FRAME);
    }
  });

  setTimeout(() => {
    DataServiceObj.getActivationCode().then((res: any) => {
      setCodeToShow(res);
    });
  }, 2000);

  setTimeout(() => {
    document
      .getElementById("activation")
      ?.setAttribute("class", "activation backimg");
  }, 3500);

  return (
    <>
      <div
        id="activation"
        className="activation"
        style={{
          backgroundColor: "black",
          height: "100vh",
          width: "100vw",
          background: `url(${SampleMeetingsSVG}) no-repeat center`,
          backgroundSize: "cover",
        }}
      >
        <div className="body" style={{ color: "white" }}>
          <div
            className="steps"
            style={{
              width: "33vw",
              float: "left",
              marginTop: "7em",
              marginRight: "2vw",
            }}
          >
            <div
              className="follow"
              style={{
                fontSize: "1.67vw",
                lineHeight: "2.25vw",
                color: " #ffffff",
                marginLeft: "1.5em",
              }}
            >
              On your computer, follow the steps below to activate your signage
            </div>
            <ol
              style={{
                fontSize: "1.95vw",
                lineHeight: "2.75vw",
                color: " #ffffff",
                counterReset: "li",
                // listStyle: "none",
                position: "relative",
                padding: 0,
                textShadow: "0 1px 0 rgba(255, 255, 255, 0.5)",
              }}
            >
              <li style={liStyle}>
                <div>
                  Login to Cisco DNA Spaces{" "}
                  <div
                    className="alink"
                    style={{
                      fontSize: "1.75vw",
                      lineHeight: "2.5vw",
                      color: "#00bceb",
                    }}
                  >
                    {Constants.BASE_URL}
                  </div>
                </div>
              </li>
              <li style={liStyle}>Launch Space Experience App</li>
              <li style={liStyle}>
                Add Non-Webex Devices :
                <br />
                <span>
                  Go to Devices &gt; Non-Webex Devices &gt; Add Signage
                </span>
              </li>
              <li style={liStyle}>
                Enter the activation code displayed to the right
              </li>
            </ol>
          </div>
          <div
            className="code-display"
            style={{
              width: "45vw",
              float: "right",
              marginTop: "6em",
              paddingLeft: "5vw",
            }}
          >
            <div className="spacer" style={{ height: "7vw" }}></div>
            <div
              className="header"
              style={{
                fontSize: "2.45vw",
                lineHeight: "2.75vw",
                color: "#ffffff",
              }}
            >
              Activation Code
            </div>
            <div
              id="code"
              className="code"
              style={{
                fontSize: "13vw",
                fontFamily: "Arial",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
                color: "#e43978",
                fontWeight: 700,
                minHeight: "1.2em",
              }}
            >
              {codeToShow}
            </div>
            <div
              style={{
                transform: `scale(${window.innerWidth / 2000})`,
                fontWeight: 850,
                alignItems: "center",
                color: "#00bceb",
                lineHeight: "5.5vw",
                fontSize: "3.27vw",
                display: "flex",
                justifyContent: "center",
                marginLeft: "-1.8em",
              }}
              className="workspaces-logo"
            >
              <img src={WorkSpacesLogoSVG} />
            </div>
            <div
              className="logo-text"
              style={{
                color: "#ffffff",
                lineHeight: "2vw",
                fontSize: "1.51vw",
                marginTop: "1em",
              }}
            >
              Enabling a safe, intelligent, and collaborative experience
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default DisplayCode;
