export class Constants {
  static watchTimeoutObj: any;
  static HEART_BEAT_INTERVAL = 30000;
  static WATCH_TIMEOUT = 5000;
  static EVENTS = {
    READ: {
      STATUS: "status",
      INIT: "init",
      SHOW_FRAME: "showFrame",
      SET_TOKEN: "setToken",
      ACTIVATE_DEVICE: "activateDevice",
      REFRESH: "refresh",
    },
    WRITE: {
      INIT: "init",
      FETCH_URL: "fetchUrl",
      HEARTBEAT: "heartbeat",
      REFRESH_TOKEN: "refreshToken",
    },
    INTERNAL: {
      SHOW_FRAME: "showFrame",
      SET_TOKEN: "setToken",
    },
  };
  static PAGES = {
    LOADER: "/loader",
    CONFIGURE: "/configure",
    DISPLAY_CODE: "/displayCode",
    SHOW_FRAME: "/showFrame",
  };
  static IFRAME_MESSAGE_TYPE = {
    GET_TOKEN: "getToken@dnas-sw",
    SET_TOKEN: "setToken@dnas-sw",
  };
  static CACHE = {
    DEVICE_TYPE: "/t/deviceType",
    DEVICE_NAME: "deviceName",
    TOKEN: "token",
    FRAME_URL: "frameUrl",
    ORG_ID: "/t/orgId",
    DEVICE_ID: "/t/deviceId",
    IS_NAVIGATOR: "isNavigator",
    NAVIGATOR_PARAMS_EXTRACT: "navigatorParamsExtract",
    CODE: "code",
    TENANT_ID_MASKED: "/t/tenantIdMasked",
    UID: "/t/uid",
  };
  static DEVICE = {
    WEBEX: "webex",
    NON_WEBEX: "non-webex",
    NAVIGATOR: "navigator",
  };
  // To avoid unexpected use of 'location' no-restricted-globals
  // eslint-disable-next-line no-restricted-globals
  static BASE_URL = `https://dnaspaces.${location.host.substring(location.host.lastIndexOf(".") + 1)}/`;
}
