export class CacheService {
  useLocalStorage = true;
  cachePointer!: Cache | Storage;
  private CONSTANTS = {
    SIGNAGE_CACHE: 'signage-cache',
  };

  constructor() {
    const cacheAvailable = 'caches' in window.self;
    this.useLocalStorage = !cacheAvailable;
  }

  loadCache = async () => {
    if (this.useLocalStorage) {
      this.cachePointer = localStorage;
    } else {
      this.cachePointer = await caches.open(this.CONSTANTS.SIGNAGE_CACHE);
    }
    return this;
  };

  put = async (key: string, value: string) => {
    if (this.useLocalStorage) {
      (<Storage>this.cachePointer).setItem(key.trim(), value);
    } else {
      this.cachePointer.put(key.trim(), new Response(value));
    }
  };

  get = async (key: string) => {
    if (this.useLocalStorage) {
      return (<Storage>this.cachePointer).getItem(key);
    } else {
      let resd: Response = await this.filter(key);
      // console.log(resd)
      return resd ? resd.text() : null;
      // return;
    }
  };

  filter = async (key: string) => {
    const response = await this.cachePointer.match(key);
    // console.log('matched: ', key, response);
    return response;
  };
}
