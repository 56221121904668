import React, { useEffect, useState } from "react";
import { DataService } from "../../utils/DataService";
import { MainService } from "../../utils/MainService";
import { Constants } from "../../models/constants";
import "./Frame.css";

function Frame({ socket }) {
  console.log("Inside Frame component");
  console.log("Socket inside frame component -> ", socket);

  const [hasIFrameURL, setHasIFrameURL] = useState(false);
  const [iFrameURL, setIFrameURL] = useState("");
  const [iFrameOrigin, setIFrameOrigin] = useState("");
  const [iWindow, setIWindow] = useState<any>(null);

  console.log("iFrameURL -> ", iFrameURL);
  console.log("iFrameOrigin -> ", iFrameOrigin);
  console.log("hasIFrameURL -> ", hasIFrameURL);
  console.log("iWindow -> ", iWindow);

  const DataServiceObj = new DataService();
  const MainServiceObj = new MainService();

  const sendRefreshTokenRequest = async () => {
    const tenantId = DataServiceObj.getTenantIdMasked();
    const payload: { [header: string]: any } = {
      tenantMask: tenantId,
      deviceParam: await DataServiceObj.getDeviceParameters(),
    };
    const deviceId = await DataServiceObj.getDeviceId();
    const orgId = await DataServiceObj.getOrgId();
    const isNav = await DataServiceObj.getIsNavigator();
    if (
      deviceId !== undefined &&
      deviceId !== "" &&
      orgId !== undefined &&
      orgId !== "" &&
      deviceId !== "null" &&
      deviceId !== null &&
      orgId !== "null" &&
      orgId !== null
    ) {
      payload["deviceId"] = deviceId;
      payload["orgId"] = orgId;
      payload["isNav"] = isNav === "true";
    }
    const getDeviceType = await DataServiceObj.getDeviceType();
    if (getDeviceType) {
      payload["deviceType"] = getDeviceType;
    }
    const token = await DataServiceObj.getDeviceToken();
    const uid = await DataServiceObj.getUid();
    if (token && uid) {
      payload["uid"] = uid; // needed to uniquely identify device
    }
    socket.send(
      JSON.stringify({
        event: "refreshToken",
        payload,
        timeStamp: new Date().getTime(),
      })
    );
  };

  useEffect(() => {
    loadFrame();
  }, [hasIFrameURL, iWindow]);

  const loadFrame = async () => {
    const getUrl = await DataServiceObj.getFrameUrl();

    console.log("Frame URL from Cache ", getUrl);
    if (getUrl)
      setTimeout(() => {
        let urlFull = getUrl;
        const urlFullQuesSplit = urlFull.split("?", 2);
        const urlFullHasSplit_01 = urlFullQuesSplit[0].split("#", 2);
        const urlFullHashSplit_02 =
          urlFullQuesSplit.length > 1
            ? urlFullQuesSplit[1].split("#", 2)
            : [""];
        // console.log(urlFullQuesSplit, urlFullHasSplit_01, urlFullHashSplit_02)
        let finalUrl =
          urlFullHasSplit_01[0] +
          "?" +
          urlFullHashSplit_02[0] +
          "&src=dnas-signage&tmp=" +
          new Date().getTime();
        if (urlFullHasSplit_01.length > 1) {
          finalUrl += "#" + urlFullHasSplit_01[1];
        } else if (urlFullHashSplit_02.length > 1) {
          finalUrl += "#" + urlFullHashSplit_02[1];
        }
        console.log("The finalUrl value is ", finalUrl);
        setIFrameURL(finalUrl);
        setHasIFrameURL(true);
        setFrameWindow();
        setIFrameOrigin(DataServiceObj.getURLOrigin(iFrameURL));
        setTimeout(() => {
          passToken();
        }, 1000);
      }, 5);
  };

  const setFrameWindow = () => {
    var iframe = document.getElementById("appFrame") as HTMLIFrameElement;
    console.log("iframe object", iframe);
    if (iframe == null) return;
    setIWindow(iframe.contentWindow);
  };

  const passToken = async () => {
    const getToken = await DataServiceObj.getDeviceToken();
    if (getToken && MainServiceObj.isValidToken(getToken)) {
      const data = {
        type: Constants.IFRAME_MESSAGE_TYPE.SET_TOKEN,
        token: getToken,
        time: new Date().getTime(),
        parent: window.location.origin,
      };
      console.log("going to send token", data, iFrameOrigin);
      try {
        sendMessage(data);
      } catch (e) {
        console.log(
          "There was an exception while sending message to iframe.",
          e
        );
      }
    } else {
      console.log(
        "Token is null or expired. Going to initiate a refresh token call to get new token"
      );
      //this.wsEventHandler.sendRefreshTokenRequest();
      sendRefreshTokenRequest();
    }
  };

  const sendMessage = (data: { [key: string]: string | number | null }) => {
    console.log("this.iWindow object", iWindow);
    if (!iWindow) {
      var iframe = document.getElementById("appFrame") as HTMLIFrameElement;
      if (iframe == null) return;
      setIWindow(iframe.contentWindow);
    }
    if (iWindow) {
      iWindow.postMessage(data, iFrameOrigin);
    }
  };

  return (
    <div className="frameDiv">
      {hasIFrameURL && (
        <iframe
          id="appFrame"
          title="Load App"
          src={iFrameURL}
          className="iFrameStyle"
        ></iframe>
      )}
    </div>
  );
}

export default Frame;
