import { Constants } from '../models/constants';
// import { ToastMessage } from "../models/toast-message";
// import { WebSocketEvent } from "../models/web-socket-event";
import { CacheService } from './CacheService';

export class DataService {
  // private tenantIdMasked = '';
  private activated = false;
  // private frameUrl = '';
  private cacheHandler!: CacheService;

  constructor() {
    this.cacheHandler = new CacheService();
  }

  public setCacheHandler = async (cacheHandler: CacheService) => {
    this.cacheHandler = cacheHandler;
  };
  public getCacheHandler = () => {
    return this.cacheHandler.loadCache();
  };

  // private static toastMessage: BehaviorSubject<ToastMessage> =
  //     new BehaviorSubject<ToastMessage>(
  //         new ToastMessage(null, 0, null, null, null)
  //     );

  // private internalEvent: BehaviorSubject<{ [key: string]: number }> =
  //   new BehaviorSubject<{ [key: string]: number }>({});

  // private webSocketEvents: BehaviorSubject<WebSocketEvent | null> =
  //     new BehaviorSubject<WebSocketEvent | null>(null);

  // private toastCallBack: BehaviorSubject<string> = new BehaviorSubject<string>(
  //   ''
  // );

  // getToastMessage = (unsubscribe$: Subject<void>) => {
  //     if (!DataService.toastMessage) {
  //         DataService.toastMessage = new BehaviorSubject<ToastMessage>(
  //             new ToastMessage(null, 0, null, null, null)
  //         );
  //     }
  //     return DataService.toastMessage.pipe(takeUntil(unsubscribe$));
  // };

  // setToastMessage = (
  //     message: string,
  //     displayForMillis: number,
  //     button: string | null,
  //     callbackKey: string | null,
  //     type: string | null
  // ) => {
  //     if (!DataService.toastMessage) {
  //         DataService.toastMessage = new BehaviorSubject<ToastMessage>(
  //             new ToastMessage(null, 0, null, null, null)
  //         );
  //     }
  //     DataService.toastMessage.next(
  //         new ToastMessage(
  //             message,
  //             displayForMillis,
  //             button,
  //             callbackKey,
  //             type
  //         )
  //     );
  // };

  // getToastCallBack = (unsubscribe$: Subject<void>) => {
  //     if (!this.toastCallBack) {
  //         this.toastCallBack = new BehaviorSubject<string>("");
  //     }
  //     return this.toastCallBack.pipe(takeUntil(unsubscribe$));
  // };

  // setToastCallBack = (callbackKey: string) => {
  //     if (!this.toastCallBack) {
  //         this.toastCallBack = new BehaviorSubject<string>("");
  //     }
  //     this.toastCallBack.next(callbackKey);
  // };

  // getInternalEvent = (unsubscribe$: Subject<void>) => {
  //     if (!this.internalEvent) {
  //         this.internalEvent = new BehaviorSubject<{ [key: string]: number }>({});
  //     }
  //     return this.internalEvent.pipe(takeUntil(unsubscribe$));
  // };

  // setInternalEvent = (internalEventKeyValue: { [key: string]: number }) => {
  //     if (!this.internalEvent) {
  //         this.internalEvent = new BehaviorSubject<{ [key: string]: number }>({});
  //     }
  //     this.internalEvent.next(internalEventKeyValue);
  // };

  // getWebSocketEvents = (unsubscribe$: Subject<void>) => {
  //     return this.webSocketEvents.pipe(takeUntil(unsubscribe$));
  // };

  // setWebSocketEvents = (event: WebSocketEvent) => {
  //     this.webSocketEvents.next(event);
  // };

  getActivationCode = () => {
    return this.getCacheHandler().then((service) =>
      service.get(Constants.CACHE.CODE)
    );
  };

  setActivationCode = (code: string) => {
    this.getCacheHandler().then((service) =>
      service.put(Constants.CACHE.CODE, code)
    );
  };

  setTenantIdMasked = (tenantIdMasked: string) => {
    this.getCacheHandler().then((service) => {
      service.put(Constants.CACHE.TENANT_ID_MASKED, tenantIdMasked);
    });
  };

  getTenantIdMasked = () => {
    return this.getCacheHandler().then((service) =>
      service.get(Constants.CACHE.TENANT_ID_MASKED)
    );
  };

  setDeviceId = (deviceId: string) => {
    this.getCacheHandler().then((service) =>
      service.put(Constants.CACHE.DEVICE_ID, deviceId)
    );
  };

  getDeviceId = () => {
    return this.getCacheHandler().then((service) =>
      service.get(Constants.CACHE.DEVICE_ID)
    );
  };

  setIsNavigator = (isNavigator: string) => {
    this.getCacheHandler().then((service) =>
      service.put(Constants.CACHE.IS_NAVIGATOR, isNavigator)
    );
  };

  getIsNavigator = () => {
    return this.getCacheHandler().then((service) =>
      service.get(Constants.CACHE.IS_NAVIGATOR)
    );
  };

  setNavigatorParamsExtract = (navigatorParamsExtract: string) => {
    this.getCacheHandler().then((service) =>
      service.put(
        Constants.CACHE.NAVIGATOR_PARAMS_EXTRACT,
        navigatorParamsExtract
      )
    );
  };

  getNavigatorParamsExtract = () => {
    return this.getCacheHandler().then((service) =>
      service.get(Constants.CACHE.NAVIGATOR_PARAMS_EXTRACT)
    );
  };

  setOrgId = (orgId: string) => {
    this.getCacheHandler().then((service) =>
      service.put(Constants.CACHE.ORG_ID, orgId)
    );
  };

  getOrgId = () => {
    return this.getCacheHandler().then((service) =>
      service.get(Constants.CACHE.ORG_ID)
    );
  };

  setActivated = (activationStatus: boolean) => {
    this.activated = activationStatus;
  };

  isActivated = () => {
    return this.activated;
  };

  setFrameUrl = (frameUrl: string) => {
    // Setting in both '/t/frameUrl' and '/frameUrl'
    this.getCacheHandler().then((service) =>
      service.put(Constants.CACHE.FRAME_URL, frameUrl)
    );
    this.getCacheHandler().then((service) =>
      service.put('/t/frameUrl', frameUrl)
    );

    // this.frameUrl = frameUrl;
  };

  getFrameUrl = () => {
    return this.getCacheHandler().then((service) =>
      service.get(Constants.CACHE.FRAME_URL)
    );
    // return this.frameUrl;
  };

  detectWebGLContext = async () => {
    try {
      // Create canvas element. The canvas is not added to the
      // document itself, so it is never displayed in the
      // browser window.
      var canvas = document.createElement('canvas');
      // Get WebGLRenderingContext from canvas element.
      var gl =
        canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      // Report the result.
      if (gl && gl instanceof WebGLRenderingContext) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  getDeviceParameters = async () => {
    return {
      'user-agent': navigator.userAgent,
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      height:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
      'web-gl': await this.detectWebGLContext(),
    };
  };

  setDeviceToken = (token: string) => {
    // Setting in both '/t/token' and '/token'
    this.getCacheHandler().then((service) => service.put('/t/token', token));

    return this.getCacheHandler().then((service) =>
      service.put(Constants.CACHE.TOKEN, token)
    );
    // localStorage.setItem('token', token);
  };

  getDeviceToken = () => {
    return this.getCacheHandler().then((service) =>
      service.get(Constants.CACHE.TOKEN)
    );
    // return localStorage.getItem('token');
  };

  setDeviceName = (name: string) => {
    return this.getCacheHandler().then((service) =>
      service.put(Constants.CACHE.DEVICE_NAME, name)
    );
  };

  getDeviceName = () => {
    return this.getCacheHandler().then((service) =>
      service.get(Constants.CACHE.DEVICE_NAME)
    );
  };

  setDeviceType = (type: string) => {
    return this.getCacheHandler().then((service) =>
      service.put(Constants.CACHE.DEVICE_TYPE, type)
    );
  };

  getDeviceType = () => {
    return this.getCacheHandler().then((service) =>
      service.get(Constants.CACHE.DEVICE_TYPE)
    );
  };
  setUid = (uid: string) => {
    return this.getCacheHandler().then((service) =>
      service.put(Constants.CACHE.UID, uid)
    );
  };

  getUid = () => {
    return this.getCacheHandler().then((service) =>
      service.get(Constants.CACHE.UID)
    );
  };

  getURLOrigin = (dataUrl: string) => {
    var a = document.createElement('a');
    a.href = dataUrl;
    return a.origin;
  };
}
