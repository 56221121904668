import React, { useEffect } from "react";
import { DataService } from "../../utils/DataService";

function ExtractParam() {
  useEffect(() => {
    console.log("Extract Param is called ...");
    const tempUrlArr = window.location.href.split("/");
    let tenantMask = tempUrlArr.slice(-1)[0];
    tenantMask = tenantMask.split("?")[0];
    console.log("tenant mask : ", tenantMask);
    const DataSerivceObj = new DataService();
    DataSerivceObj.setTenantIdMasked(tenantMask);

    const urlParams = new URLSearchParams(window.location.search);
    const deviceId = urlParams.get("deviceId");
    const orgId = urlParams.get("orgId");
    const isNav = urlParams.get("isNav") || "false";
    console.log("deviceId received:", deviceId);
    console.log("orgId received:", orgId);
    DataSerivceObj.setDeviceId(deviceId as string);
    DataSerivceObj.setOrgId(orgId as string);
    DataSerivceObj.setIsNavigator(isNav);
  }, []);

  const createPersistentCookie = () => {
    let value_or_null = (document.cookie.match(
      /^(?:.*;)?\s*uniqueId\s*=\s*([^;]+)(?:.*)?$/
    ) || [, null])[1];
    var ret_val;
    if (value_or_null == null) {
      var expiration_date = new Date();
      var cookie_string = "";
      expiration_date.setFullYear(expiration_date.getFullYear() + 1);
      cookie_string =
        "uniqueId=" +
        uuidv4() +
        "; path=/; expires=" +
        expiration_date.toUTCString();
      document.cookie = cookie_string;
    }
    return (document.cookie.match(
      /^(?:.*;)?\s*uniqueId\s*=\s*([^;]+)(?:.*)?$/
    ) || [, null])[1];
  };

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  return <></>;
}

export default ExtractParam;
