import WorkSpacesWrappedLogoSVG from "../../assets/images/workspaces-wrapped-logo.svg";
import SampleMeetingsSVG from "../../assets/images/sample-meetings.svg";
import "./Configure.css";
import Footer from "../footer/Footer";
import { Constants } from "../../models/constants";
import { DataService } from "../../utils/DataService";
import { useEffect, useState } from "react";

const liStyle: any = {
  paddingTop: "1vw",
  left: "0vw",
  marginTop: "0.3vw",
  fontWeight: "200",
  borderRadius: "2vw",
  transition: "all 0.3s ease-out",
  paddingLeft: "1.4em",
};

function Configure() {
  const DataServiceObj = new DataService();
  const [showWebex, setShowWebex] = useState(false);
  const [deviceName, setDeviceName] = useState<String | null>("");

  DataServiceObj.getDeviceType().then((deviceType) => {
    setShowWebex(deviceType === Constants.DEVICE.WEBEX);
  });

  DataServiceObj.getDeviceName().then((name) => {
    setDeviceName(name);
  });

  setTimeout(() => {
    document
      .getElementById("configure")
      ?.setAttribute("class", "configure backimg");
  }, 5);

  return (
    <div
      id="configure"
      className="configure"
      style={{
        backgroundColor: "black",
        height: "100vh",
        width: "100vw",
        background: `url(${SampleMeetingsSVG}) no-repeat center`,
        backgroundSize: "cover",
      }}
    >
      <div
        className="body"
        style={{
          fontSize: "2em",
          color: "white",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <div
          className="workspaces-panel"
          style={{
            width: "35vw",
            float: "left",
            paddingRight: "2vw",
            marginTop: "2em",
          }}
        >
          <div className="spacer" style={{ width: "8vw" }}></div>
          <div
            className="workspaces-logo"
            style={{
              transform: `scale(${window.innerWidth / 2000})`,
              fontWeight: "850",
              alignItems: "center",
              color: "#00bceb",
              lineHeight: "5.5vw",
              fontSize: "3.27vw",
              marginLeft: "1em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginTop: "2em",
            }}
          >
            <img src={WorkSpacesWrappedLogoSVG} />
          </div>
          <div
            className="logo-text"
            style={{
              lineHeight: "2.2vw",
              fontSize: "1.79vw",
              marginLeft: "2em",
              marginTop: "2em",
            }}
          >
            Enabling a safe, intelligent, and collaborative experience
          </div>
        </div>
        <div
          className="steps"
          style={{
            width: "40vw",
            float: "left",
            marginTop: "5em",
            borderLeft: "2px solid rgba(255, 255, 255, 0.5)",
            paddingLeft: "3vw",
          }}
        >
          <div
            className="follow"
            style={{
              fontSize: "1.67vw",
              lineHeight: "2.25vw",
              color: "#ffffff",
            }}
          >
            On your computer, follow the steps below to activate your signage
          </div>
          <ol
            style={{
              fontSize: "1.95vw",
              lineHeight: "2.75vw",
              color: " #ffffff",
              counterReset: "li",
              // listStyle: "none",
              position: "relative",
              padding: 0,
              textShadow: "0 1px 0 rgba(255, 255, 255, 0.5)",
            }}
          >
            <li style={liStyle}>
              <div>
                Login to Cisco DNA Spaces
                <div
                  className="alink"
                  style={{
                    fontSize: "1.75vw",
                    lineHeight: "2.5vw",
                    color: "#00bceb",
                  }}
                >
                  {Constants.BASE_URL}
                </div>
              </div>
            </li>
            <li style={liStyle}>Launch Space Experience App</li>
            <li style={liStyle}>Click Devices</li>
            <li style={liStyle}>
              Configure your content :
              <br />
              <span>
                Go to {!showWebex && <span>Non-</span>}Webex Devices and Find
                your device {deviceName} and click configure
              </span>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Configure;
